@import '../../variables.scss';

:export {
    selectedCell: $color-neutral-050;
    selectedHeader: $color-neutral-200;
    colorGray: $color-neutral-100;
}

.card {
    width: 100%;
    margin-bottom: 50px;
    min-height: 500px;
    border-radius: 12px !important;
    box-shadow:
        0px 8px 10px 0px rgba(55, 65, 81, 0.04),
        0px 4px 4px 0px rgba(153, 153, 153, 0.1) !important;
}

.container {
    width: 100%;
    max-width: none !important;
    min-width: none !important;
    padding-right: 156px !important;
    padding-left: 156px !important;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-neutral-900;
}

.header {
    height: 48px;
    background: $color-neutral-100;
    box-shadow:
        0px 1px 0px 0px $color-neutral-200 inset,
        0px -1px 0px 0px $color-neutral-200 inset;
    padding: 8px 16px 8px 16px !important;
    min-width: 80px;
    max-width: 180px;
    line-height: 16px !important;
}

.checkedIcon {
    background-color: $color-green-100 !important;
    color: $white !important;
}

.row:hover {
    background-color: $color-neutral-050;
}

.highlightedRow {
    background-color: $color-yellow-50;
}

.iconHidden {
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility !important;
    transition-timing-function: ease-in-out !important;
    transition-duration: 120ms !important;
}

.iconVisible {
    opacity: 1;
    visibility: visible;
    transition-property: opacity, visibility !important;
    transition-timing-function: ease-in-out !important;
    transition-duration: 120ms !important;
}

.noDataMessage {
    padding-top: 128px;
    width: 100%;
    text-align: center;
}

.actionCell {
    width: 125px;
}
.cell {
    height: 100px;

    .jobTitle {
        text-decoration-line: underline;
        color: $color-link !important;
        text-align: left;
    }

    .degreeRequired {
        height: 16px;
        background-color: $color-warning;
    }

    .noDegreeRequired {
        height: 16px;
        padding: unset;

        .text {
            color: $color-neutral-900;
        }
    }

    .postings {
        height: 16px;
        background-color: $color-neutral-300;
        padding: unset;
    }

    .actions {
        border-width: 1px !important;
        background-color: white;
        border-radius: 50px;
        padding: 0px 12px;
        color: $color-neutral-900;
    }

    .tooltipIcon {
        color: $color-neutral-700;
    }
}

.sortInfo {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    padding: 8px 24px;
    gap: 10px;
}

.cardHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 24px;
    .cardFilters {
        display: flex;
        gap: 8px;
    }
    .cardHeaderElements {
        display: flex;
        align-items: center;
        color: $color-link;
        gap: 10px;
    }
}

.icons {
    display: flex;
    align-items: center;
    gap: 4px;
}

.successIcon {
    color: $color-banner;
}

.warningIcon {
    color: $color-yellow-300;
}

.sortIcon {
    width: 16px;
    overflow: visible;
}

.snackbar {
    display: flex;
    border-radius: 16px;
    color: black;
    width: 300px;
    flex-direction: column;
    gap: 15px;

    .snackbarHeader {
        display: flex;
        justify-content: space-between;
    }
    .snackbarSubheader {
        display: flex;
        border-top: 1px solid $color-neutral-200;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        width: 100%;
    }
}

.link {
    color: $color-link !important;
    text-decoration: none;
}

.filterCaption {
    padding-left: 15px;
}

.selectedMenu {
    display: flex;
    color: $color-menu !important;
    align-items: center;
    gap: 5px;
}

.unselectedMenu {
    padding-left: 20px;
}
