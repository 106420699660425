@import '../../variables.scss';

.topBar {
    background-color: $white !important;
    box-shadow: none !important;
}

.userSettingMenu {
    padding: 0px 10px !important;
}

.linkText {
    color: #3d3d3d !important;
    text-decoration: unset !important;
}

.navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $white;
    padding: 0px !important;
    height: 64px;

    .navigationContent {
        display: flex;
        width: 100%;
        padding: 0px 156px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        display: flex;
        padding-top: 1px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .chipContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }

    .profileIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
    }

    .profileIconChip {
        border-width: 2px !important;
        width: 65px;
    }

    .chip {
        border-width: 0px !important;
    }
}

.subNavigation {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 0px !important;
    background-color: $white !important;
    margin-top: 64px;
    box-shadow:
        0px 4px 18px $color-drawer-shadow,
        inset 1px 0px 0px $color-neutral-300 !important;

    .subNavigationContent {
        display: flex;
        height: 67px;
        padding-left: 156px;
        align-items: center;
        gap: 40px;
    }

    .navSection {
        display: flex;
        flex-direction: column;
        width: 274px;
        min-width: 274px;
        padding-left: 12px;
        margin-top: 12px;
        margin-bottom: 12px;
        position: relative;
        cursor: pointer;
        align-items: flex-start;
        color: white !important;
        &:hover {
            transition: border-left-color 1s ease;
            border-left: 4px solid transparent;
            border-left-color: $color-banner-hover;
        }
        .arrow {
            color: $color-banner;
        }
        .title {
            display: flex;
            flex-direction: row;
            gap: 150px;
            width: 100%;
            color: black;
        }
        .headerChipContainer {
            display: flex;
            flex-direction: row;
            gap: 5px;
        }
        .headerChipHover {
            background-color: $color-banner-hover;
        }
        .headerChipActive {
            background-color: $color-banner !important;
            color: white !important;
        }
    }

    .navSection.active {
        border-left: 4px solid transparent;
        border-left-color: $color-banner;
    }
}

.keyboardArrowDownIcon {
    width: 16px !important;
    height: 16px !important;
}
