@import '../../variables.scss';

.insightsFooter {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 156px;
    align-items: flex-start;
    margin-top: 50px;
}

.caption {
    width: 766px;
    height: 141px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.chip {
    background-color: $color-primary-500 !important;
    width: 190px;
    height: 50px;
    color: $white !important;
    padding: unset;
}
