@import '../../variables.scss';

:export {
    colorPurple100: $color-purple-100;
    colorPurple60: $color-purple-60;
}

.popover {
    width: 1440px;
    background-color: $color-banner;
    display: flex;
    padding-bottom: 50px;
    justify-content: center;
    overflow-x: auto;
    white-space: no-wrap;
}

.button {
    color: white !important;
    padding: 0px !important;
    min-width: 0px !important;
}

.chip {
    background-color: white !important;
    color: $color-banner !important;
}

.chipContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.text {
    color: white !important;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.jobDetailsMain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 49px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    background-color: $color-banner;
}

.header {
    display: flex;
    height: 280px;
    min-height: 280px;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    width: 1176px;
    z-index: 1;
    background-color: $color-banner;
}

.stats {
    width: 1176px;
    display: flex;
    gap: 32px;
    .innerStats {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}

.hover {
    display: flex;
    height: 68px;
    gap: 8px;
    width: 100%;

    .hoverInfo {
        display: flex;
        width: 1000px;
        height: 68px;
        padding: 16px;
        align-items: center;
        gap: 16px;
        flex-shrink: 0;
        justify-content: space-between;
        border-radius: var(--padding-border-radius-medium, 16px);
        box-shadow: 0px 24px 50px 0px rgba(61, 61, 61, 0.25);
        background: var(--color-white, #fff);
    }

    .hoverChips {
        display: flex;
        gap: 10px;
        .export {
            background-color: $color-banner;
            color: white !important;
        }
        .checkbox {
            background-color: $white;
        }
    }

    .hoverButton {
        color: $color-banner !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--padding-border-radius-medium, 16px);
        box-shadow: 0px 24px 50px 0px rgba(61, 61, 61, 0.25);
        background: var(--color-white, #fff) !important;
        min-width: 50px;
        border: 1px solid $color-neutral-300;
        &:hover {
            background: $color-green-25 !important;
        }
        &:active {
            background: $color-green-100 !important;
            color: $white !important;
        }
    }

    .hoverStart {
        @extend .hoverButton;
        min-width: 130px !important;
    }
}

.jobDetailsStep1Main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 40px;
    z-index: 1;
    gap: 20px;
    .starsMetrics {
        width: 100%;
        justify-content: space-between;
        display: flex;
        gap: 40px;
    }
    .metric {
        width: 100%;
        gap: 15px;
        display: flex;
        flex-direction: row;
    }
    .chart {
        display: flex;
        margin-top: 24px;
        margin-bottom: 12px;
    }
    .chartLegend {
        width: 100%;
        margin-left: 10px;
        margin-top: 10px;
        .element {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
            gap: 10px;
        }
    }
}

.title {
    align-items: center;
    gap: 10px;
    display: flex;
    flex-direction: row;
}

.titleBox {
    color: white !important;
    display: flex;
    justify-content: space-between;
}

.card {
    display: flex;
    width: 1128px;
    padding: 40px 16px;
    align-items: flex-start;
    flex-shrink: 0;
    justify-content: space-around;
    min-height: 500px;
    margin-top: 20px;
    border-radius: 12px !important;
    box-shadow:
        0px 8px 10px 0px rgba(55, 65, 81, 0.04),
        0px 4px 4px 0px rgba(153, 153, 153, 0.1) !important;

    .leftData {
        width: 650px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .rightData {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        background-color: $color-neutral-100;
        width: 389px;
        gap: 15px;
        padding: 15px;
        border-radius: 16px;
    }
    .barDemographics {
        display: flex;
        justify-content: space-between;
        .barChart {
            width: 45%;
            height: 225px;
            display: flex;
            flex-direction: column;
        }
    }

    .info {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .infoText {
        display: flex;
        flex-direction: column;
    }
    .jobTitleSkillsList {
        padding: 0px !important;
        margin-left: 20px;
    }
    .listItem {
        display: list-item;
        padding: 0px 0px 5px 0px !important;
    }
}

.step4Card {
    display: flex;
    width: 1128px;
    padding: 35px 24px;
    flex-direction: column;
    margin-top: 20px;
    border-radius: 12px !important;
    box-shadow:
        0px 8px 10px 0px rgba(55, 65, 81, 0.04),
        0px 4px 4px 0px rgba(153, 153, 153, 0.1) !important;

    .cardHeader {
        width: 100%;
    }

    .cardContent {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
    }

    .actions {
        display: flex;
        width: 525px;
        flex-direction: column;
        margin-bottom: 20px;
        gap: 10px;
    }

    .description {
        width: 525px;
        border-radius: 16px;
        border: 1px dashed $color-neutral-400;
        padding: 10px;
    }
    .checkedDescription {
        @extend .description;
        background: $color-yellow-100;
        border: 1px solid $color-yellow-200;
    }
}

.typography {
    white-space: pre;
}

.link {
    color: $color-link !important;
}

.table {
    .lastRow {
        border-top: 1px solid $color-neutral-400;
    }
    th {
        padding: 0px 10px 4px;
        border-bottom: 1px solid $color-neutral-400;
        &:nth-child(2) {
            text-align: end;
        }
        &:nth-child(3) {
            text-align: end;
        }
    }
    td {
        padding: 5px 10px;
        border: none;
        &:nth-child(2) {
            text-align: end;
            padding-right: 14px;
        }
        &:nth-child(3) {
            text-align: end;
            padding-right: 14px;
        }
    }
}

.jobTitleChip {
    background-color: $color-yellow-100 !important;
    margin-top: 10px;
}

.gridItem {
    border-top: 3px solid $color-neutral-100;
}

.spiderChartCard {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.greenBackground {
    background-color: #55ae31 !important; // O el color verde que prefieras
    color: white !important;
    padding: 10px !important;
    font-size: 21px !important;
    margin-bottom: 10px;
}
