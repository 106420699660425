@import '../../variables.scss';

:export {
    colorNeutral200: $color-neutral-200;
}

.page {
    margin-left: 150px;
    margin-bottom: 100px;
}

.card {
    padding-left: 32px !important;
    padding-right: 32px !important;
    border-radius: 12px !important;
    box-shadow: 0px 8px 10px 0px rgba(55, 65, 81, 0.04), 0px 4px 4px 0px rgba(153, 153, 153, 0.1) !important;
}

.cardHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 18px;
    justify-content: space-between;
    gap: 12px;

    width: 100%;
    height: 118px;

    border-radius: 6px 6px 0px 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    .titleText {
        color: $color-neutral-900 !important;
    }
}

.cardSubtitle {
    height: auto;
    padding-top: 16px;
    max-width: 40em;
    word-wrap: break-word;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}

.buttonContainer {
    margin-top: 20px !important;
    margin-bottom: 37px !important;
}

.searchContainer {
    display: flex;
    margin-top: 60px !important;
    margin-bottom: 10px !important;
    height: 325px !important;
}

.userProfileBanner {
    padding: 32px 0px 0px 0px;
    width: 100%;
}

.testContainer {
    width: 100%;
}
