@import '../../variables.scss';

.footer {
    background-color: $color-neutral-900;
    color: $white;
    padding: 50px 156px;
    text-decoration: unset !important;
}

.footerLink {
    color: $white !important;
    text-decoration: unset !important;
}

.footerLink:hover {
    color: $color-neutral-500;
}
