@import '../../variables.scss';

.container {
    width: 100%;
    align-items: flex-start;
    column-gap: 25px;
}

.banner {
    /* Banner */

    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;

    height: 40px;
    gap: 6px;

    /* Layout Elements/surface */

    background: $color-surface;
    /* Layout Elements/Outline (N200) */

    border: 1px solid $color-neutral-200;
    /* Module Float */

    box-shadow: 0px 4px 18px rgba(26, 31, 76, 0.1);
    border-radius: 8px;
}

.icon {
    width: 24px;
    height: 24px;
    margin: auto 0px;
}

.labelContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    color: $color-neutral-900 !important;

    height: 28px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
