@import '../../variables.scss';

.snackbar {
    display: flex;
    border-radius: 16px;
    color: black;
    width: 300px;
    flex-direction: column;
    gap: 15px;

    .snackbarHeader {
        display: flex;
        justify-content: space-between;
    }
    .snackbarSubheader {
        display: flex;
        border-top: 1px solid $color-neutral-200;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        width: 100%;
    }
}

.icons {
    display: flex;
    align-items: center;
    gap: 4px;
}

.successIcon {
    color: $color-banner;
}

.reasonsSnackbar {
    display: flex;
    border-radius: 16px;
    color: black;
    width: 300px;
    flex-direction: column;
    .reasonsHeader {
        display: flex;
        justify-content: space-between;
    }

    .reasonsSubheader {
        display: flex;
        flex-direction: column;
    }

    .reasons {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
    }
}
