@import '../../../variables.scss';

.infoIcon {
    color: $color-link !important;
}

.snackbar {
    border-radius: 16px !important;
    border: 1px solid $color-neutral-200;
    box-shadow: 0px 2px 6px 0px rgba(55, 65, 81, 0.05), 0px 8px 16px 0px rgba(55, 65, 81, 0.1);
}

.icon {
    align-items: center;
    display: flex;
    gap: 10px;
}

.message {
    display: flex;
    gap: 50px;
    align-items: center;
    color: black;
}
