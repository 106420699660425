// basic colors

$surface: #ffffff;

$color-primary-overlay: rgba(190, 119, 207, 0.1);
$color-drawer-shadow: rgba(198, 202, 236, 0.25);

$color-primary-900: #26132a;
$color-primary-800: #402046;
$color-primary-700: #592d62;
$color-primary-600: #723a7e;
$color-primary-500: #8c4799;
$color-primary-400: #b668ca;
$color-primary-300: #be77cf;
$color-primary-200: #d19edd;
$color-primary-100: #e4d5eb;
$color-primary-050: #f0e0f5;
$color-primary-025: #f5ecf8;
$color-primary-hover-geo-bubble: #be77cf;

$color-neutral-1000: #1b1c1e;
$color-neutral-900: #3d3d3d;
$color-neutral-700: #50535a;
$color-neutral-600: #6a6f78;
$color-neutral-500: #8f9297;
$color-neutral-400: #e5e7eb;
$color-neutral-300: #e2e3e5;
$color-neutral-200: #ececee;
$color-neutral-100: #f5f6f6;
$color-neutral-050: #fcfcfc;

$color-solar-800: #cc8b00;
$color-solar-500: #fcb422;
$color-solar-200: #ffdf99;
$color-solar-050: #fef1d7;

$color-neptune-500: #8997fe;
$color-neptune-400: #a5b0fe;
$color-neptune-300: #b3bcfe;
$color-neptune-100: #d9deff;

$color-indigo-700: #5145cd;
$color-indigo-100: #e5edff;

$color-quark-800: #b3b62b;
$color-quark-700: #ced039;
$color-quark-500: #e3e48d;
$color-quark-300: #efefbd;

$color-flare-800: #aa4a2b;
$color-flare-500: #e87551;
$color-flare-300: #f1b19a;

$color-red-700: #8f001a;
$color-red-500: #b00020;
$color-red-400: #c32e2e;
$color-red-050: #f0d5d5;
$color-red-025: #f9ebeb;

$color-green-100: #6cc24a;
$color-green-95: #6cc24a95;
$color-green-90: #6cc24a90;
$color-green-85: #6cc24a85;
$color-green-80: #6cc24a80;
$color-green-75: #6cc24a75;
$color-green-70: #6cc24a70;
$color-green-65: #6cc24a65;
$color-green-60: #6cc24a60;
$color-green-40: #6cc24a40;
$color-green-25: #daf0d2;
$color-green-20: #daf0d24d;

$color-purple-100: #8c4799;
$color-purple-90: #8c479990;
$color-purple-80: #8c479980;
$color-purple-60: #8c479960;

$color-orange-125: #ff651a;
$color-orange-100: #ff7f41;
$color-orange-75: #ff9f71;
$color-orange-50: #ffbfa0;
$color-orange-25: #ffdfcf;

$color-yellow-300: #c8c988;
$color-yellow-200: #f3e77d;
$color-yellow-100: #ffb811;
$color-yellow-50: #fdf6b233;

$color-states-black-overlay: #000000;
$color-states-white-overlay: #ffffff;
$opacity-states-overlay-hover: 4%;
$opacity-states-overlay-focused: 12%;
$opacity-states-overlay-pressed: 10%;
$opacity-states-overlay-dragged: 8%;
$opacity-states-overlay-selected: 8%;

$color-banner: #6cc24a;
$color-banner-hover: #6cc24a80;
$color-secondary: #ffb81c;
$color-warning: #fdf6b2;
$color-tertiary: #018786;
$color-brand-green: #4298b5;
$color-no-data-available: #8f9297;
$white: #ffffff;
$color-link: #1d4289;
$color-menu: #0078d4;
$background: #f9f9f9;
$background-grey: #f5f6f6;
$background-layout-element-module: #f9fafb;
$color-surface: #ffffff;
$color-border: #e2e3e5;
$box-shadow: rgba(26, 31, 76, 0.1);
$black: #000000;
$error: #b00020;
$drawer-width: '280px';
