@import '../../variables.scss';

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 32.5px;
    padding-right: 32.5px;
    padding-bottom: 16px;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 156px;

    border-radius: 12px !important;
    box-shadow: 0px 8px 10px 0px rgba(55, 65, 81, 0.04), 0px 4px 4px 0px rgba(153, 153, 153, 0.1) !important;
    min-height: 500px;
    width: 1096px;
    overflow-wrap: break-word;

    .tabs {
        padding-top: 18px;
        display: flex;
        justify-content: space-between;
    }

    .cardHeader {
        /* Auto layout */

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 18px;
        justify-content: space-between;
        gap: 12px;

        border-radius: 6px 6px 0px 0px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .table {
        padding-top: 16px;
    }

    .note {
        padding-top: 72px;
    }

    .module {
        padding-top: 83px;
    }
}
