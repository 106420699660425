@import '../../../variables.scss';

.cardHeader {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;

    width: 100%;
    height: auto;
    border-radius: 6px 6px 0px 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.cardSubtitle {
    width: 100%;
    height: auto;
    padding-top: 16px;
    max-width: 40em;
    word-wrap: break-word;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}

.iconButton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    letter-spacing: 1.25px;
}

.buttonText {
    color: $color-surface !important;
}

.profileTable {
    width: 100%;
    height: auto;
    padding-top: 16px;
}

.profilePhoto {
    border-radius: 50%;
}

.leftColumn {
    color: $color-neutral-500 !important;
}
