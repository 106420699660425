@import '../../../variables.scss';

.subheader {
    width: 100%;
    min-width: 1140px;
    height: 682px;
    background-color: $color-banner;
    z-index: -1;
    position: absolute;
}

.text {
    padding-top: 80px;
    padding-left: 156px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.headerText {
    width: 846px;
    display: flex;
    flex-direction: column;
    color: black !important;
}

.subheaderText {
    width: 500px;
}

.graphic {
    right: 0px;
    position: absolute;
    width: 440px;
    top: -80px;
}
