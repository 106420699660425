@import '../../variables.scss';

.container {
    width: 100%;
    max-width: none !important;
    min-width: none !important;
    padding-right: 156px !important;
    padding-left: 156px !important;
}

.card {
    padding: 24px;
    margin-bottom: 200px;
    margin-top: 50px;
    border-radius: 12px !important;
    box-shadow: 0px 8px 10px 0px rgba(55, 65, 81, 0.04), 0px 4px 4px 0px rgba(153, 153, 153, 0.1) !important;

    .header {
        display: flex;
        width: 100%;
        padding: 24px 0px;
        justify-content: space-between;
        .metrics {
            display: flex;
            gap: 10px;
            width: 500px;
        }
    }
    .content {
        display: flex;
        justify-content: space-between;

        .support {
            width: 400px;
            background-color: $color-yellow-100;
            border-radius: 16px;
            display: flex;
            padding: 24px;
            flex-direction: column;
            gap: 10px;
            .chip {
                background-color: $white;
            }
        }
    }
    .tableInfo {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        align-items: center;
    }
}
